// src/App.js

import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { auth } from './firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import Chat from './Chat';
import Contacts from './components/Contacts';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { startScreenShare, stopScreenShare } from './screenShare';
import { generateRoomUrl } from './roomUrl';
import './App.css';
import Auth from './Auth';
import DatabaseTest from './components/DatabaseTest';
import {
  createPeerConnection,
  createOffer,
  handleOffer,
  handleAnswer,
  handleICECandidate,
} from './PeerConnection';

function MeetingRoom() {
  const { roomId } = useParams();
  const signalingServer = useRef(null);
  const peerConnection = useRef(null);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const [isRemoteStreamVisible, setIsRemoteStreamVisible] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [roomUrl, setRoomUrl] = useState('');
  const audioRef = useRef(null);

  useEffect(() => {
    // Create peer connection
    peerConnection.current = createPeerConnection(signalingServer.current, remoteVideoRef.current);

    peerConnection.current.ontrack = () => {
      setIsRemoteStreamVisible(true);
      stopRingtone();
    };

    // Access local media stream
    async function getLocalStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = stream;
          stream.getTracks().forEach((track) => peerConnection.current.addTrack(track, stream));
        }
      } catch (err) {
        console.error('Error accessing media devices:', err);
      }
    }

    getLocalStream();

    return () => {
      if (signalingServer.current) {
        signalingServer.current.close();
      }
      if (peerConnection.current) {
        peerConnection.current.close();
      }
    };
  }, [roomId]);

  const toggleRemoteStream = () => {
    setIsRemoteStreamVisible((prev) => !prev);
  };

  const playRingtone = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const stopRingtone = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  return (
    <div className="meeting-room">
      <h1 style={{ textAlign: 'center' }}>
        <div>Verdantoff</div>
        <div>Live Video Streaming with Chat</div>
      </h1>
      <h2>Meeting Room: {roomId}</h2>
      <div className="video-container">
        <h3>Local Stream</h3>
        <video ref={localVideoRef} autoPlay playsInline muted style={{ width: '100%', maxHeight: '400px' }} />
        {isRemoteStreamVisible && (
          <>
            <h3>Remote Stream</h3>
            <video ref={remoteVideoRef} autoPlay playsInline style={{ width: '100%', maxHeight: '400px' }} />
          </>
        )}
      </div>
      <div className="button-container">
        <button onClick={() => startScreenShare(localVideoRef)}>Share Screen</button>
        <button onClick={() => stopScreenShare(localVideoRef)}>Stop Sharing</button>
        <button onClick={() => setIsChatVisible(!isChatVisible)}>
          {isChatVisible ? 'Close Chat' : 'Open Chat'}
        </button>
        <button onClick={() => {
          const url = generateRoomUrl();
          setRoomUrl(url);
        }}>
          Generate Room URL
        </button>
      </div>
      <div className="button-container">
        <button onClick={toggleRemoteStream}>
          {isRemoteStreamVisible ? 'Close Remote Stream' : 'Open Remote Stream'}
        </button>
        <button onClick={() => signOut(auth)}>Sign Out</button>
      </div>
      {roomUrl && (
        <div className="room-url">
          <p>
            Meeting Room URL:{' '}
            <a href={roomUrl} target="_blank" rel="noopener noreferrer">
              {roomUrl}
            </a>
          </p>
        </div>
      )}
      {isChatVisible && (
        <div className="chat-container">
          <Chat />
        </div>
      )}
      <audio ref={audioRef} src="/ringtone.mp3" preload="auto" />
    </div>
  );
}

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <div className="app">
        <nav>
          <Link to="/">Home</Link> | <Link to="/contacts">Contacts</Link>
        </nav>
        <Routes>
          <Route path="/" element={user ? <MeetingRoom /> : <Auth />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/room/:roomId" element={<MeetingRoom />} />
        </Routes>
        <DatabaseTest />
      </div>
    </Router>
  );
}

export default App;
