import React, { useState, useEffect, useRef } from "react";
import { getFirestore, collection, addDoc, onSnapshot, query, orderBy, serverTimestamp, getDocs, deleteDoc } from "firebase/firestore";
import { db, auth } from "./firebaseConfig"; // Ensure you import the correct Firebase config

function Chat() {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef(null);

  const roomId = window.location.pathname.split("/")[2]; // Extract roomId from URL

  useEffect(() => {
    const messagesRef = collection(db, `rooms/${roomId}/messages`);
    const q = query(messagesRef, orderBy("timestamp", "asc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newMessages = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setMessages(newMessages);
    });

    return () => unsubscribe();
  }, [roomId]);

  useEffect(() => {
    // Scroll to the most recent message
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSendMessage = async (e) => {
    e.preventDefault(); // Prevent default form submission

    if (newMessage.trim()) {
      const message = newMessage;
      setNewMessage(""); // Clear input immediately

      try {
        await addDoc(collection(db, `rooms/${roomId}/messages`), {
          text: message,
          timestamp: serverTimestamp(),
          senderId: auth.currentUser?.uid || "Anonymous", // Fallback to 'Anonymous' if not logged in
        });
      } catch (err) {
        console.error("Error sending message:", err);
      }
    }
  };

  const handleClearChat = async () => {
    try {
      const messagesRef = collection(db, `rooms/${roomId}/messages`);
      const snapshot = await getDocs(messagesRef);

      // Use Promise.all to wait for all deletions to complete
      await Promise.all(snapshot.docs.map((doc) => deleteDoc(doc.ref)));

      setMessages([]); // Clear the chat state
    } catch (err) {
      console.error("Error clearing chat:", err);
    }
  };

  return (
    <div>
      <h2>Live Chat</h2>
      <div
        style={{
          border: "1px solid black",
          padding: "10px",
          height: "200px",
          overflowY: "scroll",
          marginBottom: "10px",
        }}
      >
        {messages.map((message) => (
          <div key={message.id}>
            {message.text && (
              <>
                <strong>
                  {message.timestamp?.toDate().toLocaleString() || "Sending..."}:
                </strong>{" "}
                {message.text}
              </>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={handleSendMessage} style={{ display: "flex", marginTop: "10px" }}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message..."
          style={{ flex: 1, marginRight: "10px", padding: "5px" }}
        />
        <button type="submit" style={{ padding: "5px 15px" }}>
          Send
        </button>
        <button
          type="button"
          onClick={handleClearChat}
          style={{ marginLeft: "10px", backgroundColor: "red", color: "white", padding: "5px 15px" }}
        >
          Clear Chat
        </button>
      </form>
    </div>
  );
}

export default Chat;
